@use "../../general/settings";

details.sc_card[open] summary:after {
	border-top: 6px solid transparent;
	border-right: 6px solid;
	border-bottom: 6px solid transparent;
	border-left: 0;
}

.sc_card {
	padding: 0;
	margin: 0;
	font-size: 28px;
	color: settings.$default-text-color;
	background: settings.$white;
	border: 1px solid rgba(0,0,0,.125);
	border-bottom: 0;
	border-radius: 0px;
	box-shadow: unset;

	summary{
		list-style: none;

		&:first-of-type {
			list-style-type: none;
		}

		&::-webkit-details-marker {
			display: none;
		}
	}

	&-question{
		position: relative;
		padding: 1rem 1.25rem;
		font-size: 14px;
		background: rgba(0,0,0,.03);
	}

	summary.sc_card-question:after {
		position: absolute;
		top: 22px;
		right: 20px;
		z-index: 10;
		display: inline-block;
		padding: 0;
		margin: 0;
		overflow: hidden;
		line-height: 15px;
		text-align: center;
		content: "";
		border-top: 6px solid;
		border-right: 6px solid transparent;
		border-left: 6px solid transparent;
	}

	&.firstItem {
		border-top: 1px solid rgba(0,0,0,.125);
		border-radius: 4px 4px 0 0;
	}

	&.lastItem {
		border-bottom: 1px solid rgba(0,0,0,.125);
		border-radius: 0 0 4px 4px;
	}

	h1, h2, h3, h4, h5, h6 {
		height: auto;
		padding: 0;
		margin: 0;
		font-family: sf_pro_textlight,Arial,sans-serif;
		font-size: 14px;
		font-weight: 400;
		color: settings.$default-text-color;
	}

	&-answer {
		padding: 1rem 1.25rem;
		margin: 0 !important;
		font-family: sf_pro_textlight,Arial,sans-serif;
		font-size: 14px;
		font-weight: 400;
		color: settings.$default-text-color;
		background: settings.$white;
		border-top: 1px solid rgba(0,0,0,.125);
	}

}