@use "sass:meta";
@use "../../variables/layout/common/rich-content-overwrites" as overwrites;
@use "../../general/mixins";
@use "../../general/settings";

.vc_column_container {
	padding: 0;
}

.vc_row,
.richContentBlock {
	@include meta.load-css("../../layout/eventpage/structured-content");

	H1, H2, H3, H4, H5, H6 {
		@include mixins.sf_pro_textsemibold;
		color: overwrites.$richContentBlock-title-color;
	}

	H1 {
		margin-top: 0;
		margin-bottom: 1em;
		font-size: 40px;
	}

	H2 {
		margin-top: 50px;
		margin-bottom: 1em;
		font-size: 30px;
	}

	H3 {
		margin-top: 1.5em;
		margin-bottom: 1em;
		font-size: 26px;
	}

	H4,
	H5,
	H6 {
		margin-top: 1.5em;
		margin-bottom: 1em;
		font-size: 22px;
	}

	.flexslider {
		box-shadow: none;
	}

	TABLE,
	TH,
	TD {
		border: 1px solid settings.$gray-placeholder;
	}

	TABLE {
		width: 100%;
		margin: 0 0 1.75em;
		table-layout: fixed;
		border-spacing: 0;
		border-collapse: separate;
		border-width: 1px 0 0 1px;
	}

	CAPTION,
	TH,
	TD {
		font-weight: normal;
		text-align: left;
	}

	THEAD TD, TH {
		background: settings.$gray-placeholder;
		border-width: 0 1px 1px 0;
	}

	TD {
		border-width: 0 1px 1px 0;
	}

	TH,
	TD {
		padding: 0.4375em;
	}

	A {
		color: overwrites.$wp-text-a-default;
		text-decoration: none;

		&:hover {
			color: overwrites.$wp-text-a-hover;
		}
	}

	.vc_message_box.wp-messageBox-info {
		display: inline-block;
		padding: 16px 16px 16px 50px;
		font-size: 14px;
		color: overwrites.$wp-messageBox-info-color;
		background: overwrites.$wp-messageBox-info-background-color;
		border: 1px solid overwrites.$wp-messageBox-info-border-color;
		border-radius: 3px;
		box-shadow: none;

		@include mixins.sf_pro_textregular();

		I {
			font-size: 20px;
			color: overwrites.$wp-messageBox-info-icon-color;
		}
	}

	.wpb_column {
		STRONG, B {
			@include mixins.sf_pro_textsemibold();
			font-weight: normal;
		}
	}

	.wpb_text_column.wp-map-info {
		display: inline-block;
		padding: 27px 27px 12px 27px;
		color: overwrites.$wp-text-column-map-info-color;
		background-color: overwrites.$wp-text-column-map-info-background;
		border: 2px solid overwrites.$wp-text-column-map-info-border;
		border-radius: 6px;

		P {
			margin: 0 0 15px 0;
			font-size: 16px;

			@include mixins.sf_pro_textregular();

			STRONG:first-of-type {
				position: relative;
				display: inline-block;
				padding-left: 20px;
				margin-bottom: 5px;
				font-weight: normal;

				@include mixins.sf_pro_textbold();

				&:before {
					position: absolute;
					top: -2px;
					left: 0;
					z-index: 10;
					display: inline-block;
					font-family: "eventTripsIcon";
					font-size: 20px;
					font-style: normal;
					font-weight: normal;
					font-display: block;
					content: "\e952";
				}
			}

			A {
				display: block;
				padding: 12px 24px;
				margin-top: 15px;
				font-size: 14px;
				color: overwrites.$wp-text-column-map-info-a-color;
				background-color: overwrites.$wp-text-column-map-info-a-background;
				border-radius: 10px;

				@include mixins.sf_pro_textsemibold();

				&:hover, &:focus {
					text-decoration: none;
				}
			}
		}
	}

	// .wpb_single_image {
	// 	width: 100%;
	// 	FIGURE.wpb_wrapper.vc_figure {
	// 		width: 100%;
	// 		.vc_single_image-wrapper {
	// 			width: 100%;
	// 			IMG {
	// 				width: 100%;
	// 			}
	// 		} 
	// 	}
	// } 

	.googleMap, .wpb_wrapper IFRAME {
		width: 100%;
	}

}